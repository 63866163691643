<template>
  <div class="weekSelect">
    <el-select v-model="innerValue" multiple collapse-tags clearable :placeholder="$t('placeholder.defaultSelect')" @change="handleChange">
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.name"
        :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default () {
        return []
      }
    },
    object: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      innerValue: [],
      options: [{
        name: this.$t('text.sriday'),
        value: 0
      }, {
        name: this.$t('text.monday'),
        value: 1
      }, {
        name: this.$t('text.tuesday'),
        value: 2
      }, {
        name: this.$t('text.wednesday'),
        value: 3
      }, {
        name: this.$t('text.thursday'),
        value: 4
      }, {
        name: this.$t('text.friday'),
        value: 5
      }, {
        name: this.$t('text.saturday'),
        value: 6
      }]
    }
  },
  watch: {
    value: {
      handler () {
        this.innerValue = this.value
        // console.log(this.value)
      }
    }
  },
  created () {
    // console.log(this.value)
    // this.innerValue = this.value
  },
  methods: {
    handleChange () {
      // const arr = []
      // this.options.forEach(res => {
      //   if (this.innerValue.includes(res.value)) {
      //     arr.push(1)
      //   } else {
      //     arr.push(0)
      //   }
      // })
      this.$emit('update:value', this.innerValue)
    }
  }
}
</script>

<style lang="scss" scope>
.weekSelect {
  width: 100%;
}
</style>
